import React, { Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, Grid, useTheme } from '@mui/material';
import SnackbarProvider from '~/components/Snackbar';
import { Workbox } from 'workbox-window';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary as _ErrorBoundary } from '~/components/ErrorBoundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Global } from '@emotion/react';
import Theme from '~/components/Theme';
import { rootRoutes } from '~/routes';
import { useRoutes } from '~/utils/contexts';
import * as Sentry from '@sentry/react';

import {
	faArrowDown,
	faArrowLeft as fasArrowLeft,
	faArrowUp,
	faArrowRight,
	faCloudUpload as fasCloudUpload,
	faDown,
	faEye as fasEye,
	faFile,
	faGrid,
	faSquareKanban,
	faUp,
	faUserGroup,
	faUsers,
	faPencil,
	faTrash,
	faLock,
	faTag,
	faCartShopping,
	faTrashRestore,
} from '@fortawesome/pro-solid-svg-icons';
import {
	faBarsStaggered as fadBarsStaggered,
	faImages as fadImages,
	faMountainSun as fadMountainSun,
	faPieChart as fadPieChart,
	faQrcode as fadQrcode,
	faServer as fadServer,
	faSignsPost as fadSignsPost,
	faTablet as fadTablet,
} from '@fortawesome/pro-duotone-svg-icons';
import globalStyles from './globalStyles';
import RoutesProvider from '~/components/Routes';
import AuthProvider from '~/utils/auth';
import ArmaKarmaSpinner from '~/components/ArmaKarmaSpinner';

library.add(
	faTrashRestore,
	faCartShopping,
	faTag,
	faLock,
	faTrash,
	faPencil,
	faArrowRight,
	fasEye,
	fasArrowLeft,
	fadPieChart,
	fadMountainSun,
	fadServer,
	fadTablet,
	fadSignsPost,
	fadQrcode,
	fadImages,
	fasCloudUpload,
	fadBarsStaggered,
	faUp,
	faDown,
	faArrowUp,
	faArrowDown,
	faUsers,
	faSquareKanban,
	faUserGroup,
	faFile,
	faGrid
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) =>
				!(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
		mutations: {
			// Offline support
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
	},
});

const App = () => {
	const [initialising, setInitialising] = useState(true);
	const theme = useTheme();
	const { router, routerRoutes } = useRoutes();

	// TODO: Use real precache from RDM in the future if it's an experience

	return (
		<Suspense
			fallback={
				<Grid
					className="loader"
					container
					xs={12}
					justifyContent="center"
					alignItems="center"
					height="100vh"
				>
					<Grid item>
						<ArmaKarmaSpinner color="green" size={50} />
					</Grid>
				</Grid>
			}
		>
			<Global styles={globalStyles(theme)} />
			{router}
		</Suspense>
	);
};

const Root = () => {
	return (
		<BrowserRouter>
			<Theme>
				<Sentry.ErrorBoundary
					fallback={_ErrorBoundary}
					onReset={() => {
						// reset the state of your app so the error doesn't happen again
					}}
				>
					<QueryClientProvider client={queryClient}>
						<SnackbarProvider>
							<RoutesProvider routes={rootRoutes}>
								<AuthProvider>
									<CssBaseline />
									<App />
								</AuthProvider>
							</RoutesProvider>
						</SnackbarProvider>
					</QueryClientProvider>
				</Sentry.ErrorBoundary>
			</Theme>
		</BrowserRouter>
	);
};

Sentry.init({
	dsn: 'https://a275fec85f144166a69d6199f766f5fc@o180152.ingest.sentry.io/4505125411094528',
	integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
	// Performance Monitoring
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// @ts-ignore
createRoot(document.getElementById('root')).render(<Root />);

if ('serviceWorker' in navigator) {
	const wb = new Workbox('/sw.js');

	wb.addEventListener('waiting', (event) => {
		console.log('WAITING');

		wb.addEventListener('controlling', () => {
			// At this point, reloading will ensure that the current
			// tab is loaded under the control of the new service worker.
			// Depending on your web app, you may want to auto-save or
			// persist transient state before triggering the reload.
			window.location.reload();
		});

		wb.messageSkipWaiting();

		window.addEventListener('beforeunload', async () => {
			await wb.messageSkipWaiting();
		});
	});

	wb.register();
}
